document.addEventListener('DOMContentLoaded', function() {

    const el = document.getElementsByClassName('js-topbar')[0];

    const action = function() {
        
        let scroll_pos = window.pageYOffset || window.scrollY;

		// Narrow top
        if (scroll_pos > el.clientHeight) {
            el.classList.add('is-narrow');
        } else {
            el.classList.remove('is-narrow');
        }
    }
    
    el ? window.addEventListener('scroll', action) : false;
    
    

}, false);