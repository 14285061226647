import GLightbox from '../../node_modules/glightbox/dist/js/glightbox.js';
require('../../node_modules/glightbox/dist/css/glightbox.css');

document.addEventListener('DOMContentLoaded', function() {

    function gtag_report_conversion(url) {
     var callback = function () {
      if (typeof(url) != 'undefined') {
       //window.location = url;
      }
     };
     gtag('event', 'conversion', {
       'send_to': 'AW-11468753391/1JhACIXlmokZEO-T3dwq',
       'event_callback': callback
     });
     return false;
    }

    if (document.getElementsByClassName('js-ufll').length > 0) {    
        const ufll_join = GLightbox({
            selector: '.js-ufll'
        });
        
        ufll_join.on('open', () => {
            gtag_report_conversion(ufll_join.elements[0].href);            
        });
    }
    
    if (document.getElementsByClassName('js-image').length > 0) {
        const image = GLightbox({
            selector: '.js-image'
        });
    }

    if (document.querySelectorAll("[href*='youtube']").length > 0) {
        if (document.documentElement.classList.contains('desktop')) {
            const video = GLightbox({
                selector: "[href*='youtube']"
            });
        } else {
            const video = GLightbox({
                selector: "[href*='youtube']",
                autoplayVideos: false
            });
        }
        
        
    }

}, false);
