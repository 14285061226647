//import { Swiper, Navigation, Autoplay, EffectCreative } from 'swiper';
import { Swiper, Navigation, Pagination, Autoplay } from 'swiper';

//import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

//Swiper.use([Navigation, Autoplay, EffectCreative]);
Swiper.use([Autoplay, Navigation, Pagination]);


document.addEventListener('DOMContentLoaded',function() {

    const about = document.getElementsByClassName('js-aboutSwiper'),
          clients = document.getElementsByClassName('js-clientsSwiper')[0],
          foryoung = document.getElementsByClassName('js-foryoungSwiper')[0],
          grid = document.getElementsByClassName('js-gridSwiper')[0],
          honored = document.querySelector('.js-honoredSwiper');

    const aboutCarousel = function() {            	    
        for (let i = 0; i < about.length; i++) {
            const swiper = new Swiper(about[i], {
                autoplay: {
            	    delay: 5000
        	    },
        	    loop: true,
        	    pagination: {
                    el: about[i].getElementsByClassName('swiper-pagination')[0],
                    clickable: true
                },
                navigation: {
                    nextEl: about[i].getElementsByClassName('swiper-button-next')[0],
                    prevEl: about[i].getElementsByClassName('swiper-button-prev')[0],
                },
                spaceBetween: 10,
                speed: 800
            });
        }
    };

    const clientsCarousel = function() {            	    
        
        const logotypes = document.getElementsByClassName('js-logotypes')[0],
              logotypes_item = logotypes.getElementsByTagName('li');

        const swiper = new Swiper(clients, {
            autoplay: {
        	    delay: 5000
    	    },
    	    loop: true,
    	    navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.c-clients .swiper-pagination',
                    clickable: true
            },
            on: {
                slideChange: function(e) {
                    for (let i = 0; i < logotypes_item.length; i++) {
                        logotypes_item[i].classList.remove('is-active');
                    }
                    
                    let index = e.activeIndex-1;
                    
                    index === 4 ? index = 0 : false;                    
                    index === -1 ? index = 3 : false;

                    logotypes_item[index].classList.add('is-active');
                }
            },
            speed: 800
        });
        
        for (let i = 0; i < logotypes_item.length; i++) {
            logotypes_item[i].addEventListener('click', function(e) {
                swiper.slideTo(3);
                
                /*
for (let j = 0; j < logotypes_item.length; j++) {
                    logotypes_item[j].classList.remove('is-active');
                    console.log( logotypes_item[j] );
                }
*/
                
                let index = cutme.Helpers.thisIndex(e.currentTarget);
                swiper.slideTo(index+1);
                
                //e.currentTarget.classList.add('is-active');
            });
        }
    };
    
    const foryoungCarousel = function() {            	    
        
        const swiper = new Swiper(foryoung, {
            autoplay: {
        	    delay: 50001
    	    },
    	    loop: true,
    	    navigation: {
                nextEl: '.o-youngcarousel .swiper-button-next',
                prevEl: '.o-youngcarousel .swiper-button-prev',
            },
    	    pagination: {
                el: '.o-youngcarousel .swiper-pagination',
                    clickable: true
            },
            speed: 800
        });
    };
    
    const gridCarousel = function() {
        
        let swiper, mobile;

        const carousels = {
            destroy: function() {
                if (swiper != undefined) {
                    swiper.destroy(true,true);
                }
            },
            start: function() {
                swiper = new Swiper(grid, {
                    autoplay: {
                	    delay: 50001
            	    },
            	    loop: true,
            	    pagination: {
                        el: '.c-grid .swiper-pagination',
                            clickable: true
                    },
            	    slidesPerView: 'auto',
                    speed: 800
                });
            }
        }
        
        const init = function() {
            if (window.innerWidth > 768) {
                if (mobile === true) {
                    carousels.destroy();
                    mobile = false;
                }

            } else {
                if (!mobile) {
                    carousels.start();
                    mobile = true;
                }
            }
        }
        
        init();
        window.addEventListener('resize', init);
    };
    
    const honoredCarousel = () => {
        let mediaQuery = '(max-width: 768px)',
            mediaQueryList = window.matchMedia(mediaQuery),
            swiper;
        
        const start = () => {
            swiper = new Swiper(honored, {
                autoHeight: true,
        	    loop: true,
        	    pagination: {
                    el: honored.querySelector('.swiper-pagination'),
                    clickable: true
                },
            });
        }
        
        mediaQueryList.addEventListener('change', event => {
            event.matches ? start() : swiper.destroy();
        })

        mediaQueryList.matches ? start() : false;
    };

    window.carousels = function() {
        about.length > 0 ? aboutCarousel() : false;
        clients ? clientsCarousel() : false;
        foryoung ? foryoungCarousel() : false;
        grid ? gridCarousel() : false;
        honored ? honoredCarousel() : false;
    }

}, false)
